// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/OpenSans-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/OpenSans-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/OpenSans-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/OpenSans-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Open Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-weight:300;font-style:normal;font-display:swap}@font-face{font-family:\"Open Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");font-weight:normal;font-style:normal;font-display:swap}@font-face{font-family:\"Open Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:\"Open Sans\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");font-weight:bold;font-style:normal;font-display:swap}", "",{"version":3,"sources":["webpack://./fonts.scss"],"names":[],"mappings":"AAAA,WACE,uBAAA,CACA,2DAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CAGF,WACE,uBAAA,CACA,2DAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CAGF,WACE,uBAAA,CACA,2DAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CAGF,WACE,uBAAA,CACA,2DAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":["@font-face {\n  font-family: 'Open Sans';\n  src: url('fonts/OpenSans-Light.woff2') format('woff2');\n  font-weight: 300;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'Open Sans';\n  src: url('fonts/OpenSans-Regular.woff2') format('woff2');\n  font-weight: normal;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'Open Sans';\n  src: url('fonts/OpenSans-SemiBold.woff2') format('woff2');\n  font-weight: 600;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'Open Sans';\n  src: url('fonts/OpenSans-Bold.woff2') format('woff2');\n  font-weight: bold;\n  font-style: normal;\n  font-display: swap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
